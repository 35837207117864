<template>
  <div class="info-wrapper">
    <p-header></p-header>
    <div class="banner">
      <img src="~common/images/info_top.png" width="100%" alt="">
      <div class="banner-title">关于我们</div>
    </div>
    <div class="info-container">
      <div class="info-content-box">
        <div class="info-item-box">
          <p class="text">公司背景</p>
          <p class="subtext">数码通科技成立于2016年，一直以来奉行解决各类场景下企业内部供应链及对外品宣营销的各类诉求为服务宗旨。通过自主知识产权的物联网与互联网相结合的先进技术，以数据为依托，通过一物一码、平台搭建、整合营销等解决方案，为企业打造不同场景下人、货、场的沟通生态链。打破内部管理与对外营销之间的壁垒，为企业实现经营目标赋能，共同链接智能未来。</p>
          <div class="subtext">稿真无忧<div style="font-size: 0.07rem;display: inline;vertical-align: super;">®</div>产品团队由多名资深的包装印刷行业专家及互联网技术专家组成。团队致力于通过自主知识产权的物联网与互联网相结合的先进技术，秉承为B端企业打造好用的数智化产品，在供应链数字化管理、产品全生命周期追溯、一物一码创意营销等领域，帮助快消品企业完成数智化产业升级。</div>
        </div>
        <div class="info-item-box">
          <p class="text">产品矩阵</p>
          <div class="subtext">数码通旗下目前共有三款核心SaaS产品：稿真无忧<div style="font-size: 0.07rem;display: inline;vertical-align: super;">®</div>包装设计稿管理平台、营销云平台、追溯防窜平台。</div>
          <div class="subtext">稿真无忧<div style="font-size: 0.07rem;display: inline;vertical-align: super;">®</div>包装设计稿管理平台本着将企业员工从重复性的流程工作中解放出来，更多地投身于产品创新的初衷，将包装设计稿流程管理与专业的设计稿印前交互工具融为一体，打造出更适合于快消品企业的包装供应链管理平台。</div>
          <div class="subtext">稿真无忧<div style="font-size: 0.07rem;display: inline;vertical-align: super;">®</div>通过统一管理内外部相关人、规范稿件存储分发、灵活流程设置、机器辅助校对，为企业包装供应链管理提供切实可行的品控增效方案，推动供应链管理向数字化迈进。 </div>
          <p class="subtext">营销云是数码通自主研发的、旨在为快消品品牌提供一站式营销解决方案的数字化营销平台。通过营销云平台，可以帮助企业快速推出模块化定制营销活动，丰富的活动类型可以选择，自由配置活动信息，结合会员系统与数据管理，全面把控活动落地效果。 </p>
          <p class="subtext">追溯防窜平台通过自动化采集从灌装生产、出入库到渠道运输的各个环节生产物流数据，在有效控制人力成本的情况下，打造企业信息化追溯体系。实现正向追溯，反向溯源，双向追溯保证质量管控，从而让企业对产品的流向了如指掌，让消费者对产品质量更有信心。</p>
        </div>
        <div class="info-bottom-box">平台运营委托：北京创新一点通数码科技有限公司</div>
      </div>
    </div>
    <p-footer></p-footer>
  </div>
</template>

<script type="text/ecmascript-6">
  import PHeader from 'components/header/header'
  import PFooter from 'components/footer/footer'

  export default {
    data() {
      return {}
    },
    /* eslint-disable no-undef */
    created() {
    },
    methods: {},
    watch: {},
    components: {
      PHeader,
      PFooter
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .info-wrapper
    width 100%
    min-width 1270px
    .banner
      padding-top: 0.35rem;
      position relative
      text-align: center
      width: 100%
      .banner-title
        position absolute
        top 1.1rem
        left 51%
        transform: translateX(-50%)
        font-size: 0.33rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.44rem;
    .info-container
      width 100%
      box-sizing border-box
      .info-content-box
        background: #FFFFFF;
        .info-bottom-box
          font-size: 0.08rem;
          font-weight: 400;
          color: #666666;
          text-align: center
          margin-top: 0.8rem
          margin-bottom: 0.2rem
        .info-item-box
          color #333
          text-align left
          box-sizing border-box
          padding .45rem 2.29rem .21rem 2.29rem
          .text
            font-size: 0.23rem;
            font-weight: bold;
            color: #1A1A1A;
            letter-spacing: 1px;
          .subtext
            font-size: 0.12rem;
            font-weight: 400;
            color: #666666;
            line-height: 0.24rem
            margin-top: 0.2rem
            letter-spacing: 1px;
        .info-contact-box
          padding-bottom 0.3rem
          text-align center
          color #333
</style>
