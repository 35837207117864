<template>
  <div class="header-wrapper">
    <div class="header-container">
      <a href="">
        <img src="~common/images/top_logo.png" class="logo-box" alt="">
      </a>
      <ul class="header-tab">
        <li class="tab-item"><router-link class="active"  to="/" >产品介绍</router-link></li>
        <li class="tab-item"><router-link  class="active"  to="/case">产品定价</router-link></li>
        <li class="tab-item"><router-link class="active"  to="/info">关于我们</router-link></li>
      </ul>
      
      <a  @click="tapLogin"><img src="~common/images/top_login.png" class="btn1-box" alt="" /></a>
      <img src="~common/images/top_tel.png" class="tel-box" alt="">
      <div class="telnum-box">86-1064356368-1702</div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  export default {
    data() {
      return {
        selectIndex: 1
      }
    },
    created() {
    },
    activated() {
      // if (this.$route.path.indexOf('info') > -1) {
      //   this.selectIndex = 4
      // } else if (this.$route.path.indexOf('service') > -1) {
      //   this.selectIndex = 3
      // } else if (this.$route.path.indexOf('case') > -1) {
      //   this.selectIndex = 2
      // } else {
      //   this.selectIndex = 1
      // }
    },
    methods: {
      tapLogin() {
        this.$router.push({
          name: 'service'
        })
      }
    },
    watch: {},
    components: {}
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .header-wrapper
    position fixed
    top 0
    left 0
    width 100%
    min-width 1270px
    height 0.36rem
    background: #F6F6F6
    z-index: 99
    .header-container
      width 100%
      height 100%
      margin 0 auto
      .header-tab
        display inline-block
        height 100%
        margin-left 2.7rem
        float left
        cursor pointer
        .tab-item
          position relative
          display inline-block
          padding-left .08rem
          padding-right .08rem
          height 0.36rem
          font-size: 0.09rem;
          line-height: 0.36rem;
          box-sizing border-box
          font-weight: bold;
          color #000000
          text-align center
          .active
            font-weight: bold;
          .unactive
            font-weight: bold;
    .logo-box
      display inline-block
      float left
      width: 0.7rem
      padding-top .09rem
      margin-left 1.5rem
    .tel-box
      display inline-block
      float left
      width:0.12rem
      height .12rem
      padding-top .13rem
      margin-left 0.2rem
    .telnum-box
      display inline-block
      margin-left 0.05rem
      font-size: 0.09rem;
      font-weight: 400;
      color: #000000;
      line-height 0.36rem
    .btn1-box
      display inline-block
      float left
      cursor pointer
      margin-left 0.82rem
      margin-top: 0.1rem;
      width: 0.54rem;
      height: 0.17rem;
      text-align: center
</style>
